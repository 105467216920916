import { useState, useEffect } from "react";
import {
    IconButton,
    TextField,
    Box,
    Button,
    Typography,
    Modal,
    ListItem,
    ListItemText,
    Autocomplete,
} from "@mui/material";
import { Stack } from "@mui/system";
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormHelperText } from "@mui/material";
import youtubeParser from "../utils/youtubeParser";
import getYoutubeVideoInfo from "../services/youtubeApi";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { typeOptions } from "../constants";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "15px",
    width: {
        md: "350px",
        lg: "500px",
        xs: "310px",
    },
};

const isValidYoutubeUrl =
    /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;

export default function AddLinkModal({
    isOpen,
    value,
    handleClose,
    type,
    onAdd,
    onAddAnother,
}) {
    const [modalInput, setModalInput] = useState(value);
    const [title, setTitle] = useState(value);
    const [typeSelection, setTypeSelection] = useState(type);
    const [fileInput, setFileInput] = useState(null);
    const [isValidLink, setIsValidLink] = useState(true);
    const [isTitleValid, setIsTitleValid] = useState(true);
    const [isFileValid, setIsFileValid] = useState(true);

    useEffect(() => {
        setModalInput(value);
    }, [value]);

    useEffect(() => {
        setTypeSelection(type);
    }, [type]);

    const handleModelClose = () => {
        setFileInput(null)
        setModalInput(null)
        setTitle(null)
        setIsValidLink(true);
        setIsTitleValid(true);
        setIsFileValid(true);
        handleClose();
    };

    const checkYoutubeVideoId = async () => {
        return new Promise((resolve, reject) => {
            getYoutubeVideoInfo(modalInput).then((response) => {
                if (response.data.items.length > 0) {
                    resolve(true);
                } else {
                    reject(false);
                }
            });
        });
    };

    const handleAddBtnClick = async () => {
        if (typeSelection === 'Video') {
            if (!isValidYoutubeUrl.test(modalInput) || !youtubeParser(modalInput)) {
                setIsValidLink(false);
                return;
            }
            await checkYoutubeVideoId()
                .then(() => {
                    setIsValidLink(true);
                    onAdd({ type: typeSelection, link: modalInput });
                    setModalInput("");
                })
                .catch(() => {
                    setIsValidLink(false);
                    return;
                });
        } else if (typeSelection === 'Audio') {
            if (!modalInput) {
                setIsValidLink(false); return
            }
            if (!title) {
                setIsTitleValid(false); return
            }
            setIsValidLink(true);
            setIsTitleValid(true)
            onAdd({ type: typeSelection, link: modalInput, title });
            setModalInput("");
        } else {
            if (fileInput == null) {
                setIsFileValid(null);
                return;
            } else {
                onAdd({ type: typeSelection, file: fileInput });
                setFileInput(null)
                setIsFileValid(true)
            }
        }
    };

    const handleAddAnotherBtnClick = async () => {
        if (typeSelection === 'Video') {
            if (!isValidYoutubeUrl.test(modalInput) || !youtubeParser(modalInput)) {
                setIsValidLink(false);
                return;
            }
            await checkYoutubeVideoId()
                .then(() => {
                    setIsValidLink(true);
                    onAddAnother({ type: typeSelection, link: modalInput });
                    setModalInput("");
                })
                .catch(() => {
                    setIsValidLink(false);
                    return;
                });
        } else if (typeSelection === 'Audio') {
            if (!modalInput) {
                setIsValidLink(false); return
            }
            if (!title) {
                setIsTitleValid(false); return
            }
            setIsValidLink(true);
            setIsTitleValid(true);
            onAddAnother({ type: typeSelection, link: modalInput, title });
            setModalInput("");
        } else {
            if (fileInput === null) {
                setIsFileValid(null);
                return;
            } else {
                onAddAnother({ type: typeSelection, file: fileInput });
                setFileInput(null)
                setIsFileValid(true)
            }
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileInput(file);
        setIsFileValid(true);
    };
    return (
        <div>
            <Modal
                open={isOpen}
                onClose={handleModelClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: "10px",
                        }}
                    >
                        <Typography
                            align="center"
                            sx={{
                                fontWeight: "bold",
                                fontSize: {
                                    md: "22px",
                                },
                            }}
                        >
                            {value
                                ? "Edit Resource :"
                                : "Add Resource :"}
                        </Typography>
                        <IconButton color="error" onClick={handleModelClose} edge="end">
                            <CancelIcon
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: {
                                        md: "30px",
                                    },
                                }}
                            />
                        </IconButton>
                    </Box>
                    <Box>
                        <Autocomplete
                            disablePortal
                            disableClearable
                            options={typeOptions}
                            size="small"
                            value={typeSelection}
                            onInputChange={(event, value) => setTypeSelection(value)}
                            sx={{ marginRight: 2, my: 1, width: 200 }}
                            renderInput={(params) => (
                                <TextField size="small" {...params} label="Select Type" />
                            )}
                        />
                        <div>
                            {typeSelection === 'Audio' || typeSelection === 'Video' ?
                                <>
                                    {typeSelection === 'Audio' && (
                                        <>
                                            <TextField
                                                value={title}
                                                error={!isTitleValid}
                                                fullWidth
                                                sx={{ my: 1 }}
                                                onChange={(event) => setTitle(event.target.value)}
                                                // helperText={!isValidLink ? "Please enter valid link" : ""}
                                                InputProps={{
                                                    style: {
                                                        borderRadius: "15px",
                                                    },
                                                }}
                                                label="Title"
                                            />
                                            {!isTitleValid && (
                                                <FormHelperText
                                                    error
                                                    sx={{
                                                        fontSize: {
                                                            md: "18px",
                                                            xs: "15px",
                                                            sm: "15px",
                                                        },
                                                    }}
                                                >
                                                    Please enter valid title
                                                </FormHelperText>
                                            )}
                                        </>
                                    )}
                                    <TextField
                                        value={modalInput}
                                        error={!isValidLink}
                                        fullWidth
                                        onChange={(event) => setModalInput(event.target.value)}
                                        // helperText={!isValidLink ? "Please enter valid link" : ""}
                                        InputProps={{
                                            style: {
                                                borderRadius: "15px",
                                            },
                                        }}
                                        label={`Add ${typeSelection} link`}
                                    />
                                    {!isValidLink && (
                                        <FormHelperText
                                            error
                                            sx={{
                                                fontSize: {
                                                    md: "18px",
                                                    xs: "15px",
                                                    sm: "15px",
                                                },
                                            }}
                                        >
                                            Please enter valid link
                                        </FormHelperText>
                                    )}
                                </>
                                :
                                <>
                                    {!fileInput ?
                                        <>

                                            <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                                Upload Document
                                                <VisuallyHiddenInput type="file" onChange={handleFileChange} accept=".pdf,.doc,.docx" />
                                            </Button>
                                            {!isFileValid && (
                                                <FormHelperText
                                                    error
                                                    sx={{
                                                        fontSize: {
                                                            md: "18px",
                                                            xs: "15px",
                                                            sm: "15px",
                                                        },
                                                    }}
                                                >
                                                    {isFileValid == null ? 'Please Choose a file first' : 'Please choose a valid file'}
                                                </FormHelperText>
                                            )}
                                        </>
                                        :
                                        <>
                                            <ListItem
                                                secondaryAction={
                                                    <IconButton color="error" onClick={() => setFileInput(null)} edge="end">
                                                        <DeleteIcon
                                                            sx={{
                                                                fontWeight: "bold",
                                                                fontSize: {
                                                                    md: "22px",
                                                                },
                                                            }}
                                                        />
                                                    </IconButton>
                                                }
                                            >
                                                <ListItemText primary={`${fileInput.name}`} />
                                            </ListItem>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </Box>
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 1, sm: 2, md: 2 }}
                        margin="10px"
                        justifyContent={{ md: "flex-end" }}
                    >
                        {!value && (
                            <Button
                                color="inherit"
                                variant="contained"
                                onClick={handleAddAnotherBtnClick}
                                disableElevation
                            >
                                Add Another
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            color={value ? "warning" : "primary"}
                            onClick={handleAddBtnClick}
                            disableElevation
                        >
                            {value ? "Edit" : "Add"}
                        </Button>
                    </Stack>
                    <Box
                        sx={{ mt: "10px", display: "flex", justifyContent: "space-evenly" }}
                    ></Box>
                </Box>
            </Modal>
        </div>
    );
}
