import {
  get_request,
  post_request,
  delete_request,
  put_request,
} from "./requests";

// post the resources
const addResource = (data) => {
  return new Promise((resolve, reject) => {
    post_request("admin/resources", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// post the document resources
const addDocumentResourceApi = (data) => {
  return new Promise((resolve, reject) => {
    post_request("admin/resources/document", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// update the resources
const updateResource = (id, data) => {
  return new Promise((resolve, reject) => {
    put_request(`admin/resources/${id}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// delete the resources based on the id
const deleteResource = (id) => {
  return new Promise((resolve, reject) => {
    delete_request(`admin/resources/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// get all the resources by filters
const getResource = (params) => {
  return new Promise((resolve, reject) => {
    get_request("admin/resources/list", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// get all the resources details by filters
const getResourceDetail = (params) => {
  return new Promise((resolve, reject) => {
    get_request("admin/resources/details/list", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getResourceById = (id) => {
  return new Promise((resolve, reject) => {
    get_request(`admin/resources/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export {
  addResource,
  addDocumentResourceApi,
  updateResource,
  getResourceById,
  getResource,
  getResourceDetail,
  deleteResource,
};
