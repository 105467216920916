import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  Typography,
  Button,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";

import CustomizedTables from "../components/Table";

import {
  resultOptions,
  typeOptions,
  columnHeading,
  moodOptions,
} from "../constants";

import { getResource } from "../services/resourceApi";
import Loader from "../components/Loader";

function ManageResourcePage() {
  const [resultSelection, setResultSelection] = useState("");
  const [typeSelection, setTypeSelection] = useState("Video");
  const [moodSelection, setMoodSelection] = useState("Peace");
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    getResource({
      result: resultSelection === "" ? null : resultSelection,
      type: typeSelection === "" ? null : typeSelection,
      mood: resultSelection !== "General" ? null : moodSelection,
    })
      .then((response) => setData(response.data))
      .catch((error) => setError(error.data));

    if (error) console.log(error);
  }, [typeSelection, resultSelection, moodSelection, error]);

  if (!data)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Loader color={"success"} />
      </div>
    );

  // const handleDeleteResourceBtnClick = (resource) => {
  //   setShowAlertDialog(true);
  //   setDeleteResourceId(resource.id);
  // };

  const resourceData = data.map((resource) => {
    return {
      id: resource.id,
      result: resource.result,
      type: resource.type,
      mood: resource.mood,
      total_resource: resource.count_resources,
      // action_delete: (
      //   <>
      //     <IconButton
      //       color="primary"
      //       edge="start"
      //       LinkComponent={NavLink}
      //       to={`/resource/add?result=${resource.result}&mood=${resource.mood}&type=${resource.type}`}
      //     >
      //       <EditIcon />
      //     </IconButton>
      //     <IconButton
      //       color="error"
      //       onClick={() => {
      //         handleDeleteResourceBtnClick(resource);
      //       }}
      //       edge="end"
      //     >
      //       <DeleteIcon />
      //     </IconButton>
      //   </>
      // ),
    };
  });

  return (
    <>
      <Grid container>
        <Grid item md={12} xs={12} lg={12}>
          <Typography
            sx={{
              ml: "6px",
              fontWeight: "bold",
              fontSize: {
                md: "22px",
              },
            }}
          >
            Filter Resources by Result and Type
          </Typography>
        </Grid>
        <Grid container sx={{ marginY: "10px" }}>
          <Grid item xs={12} md="auto">
            <Autocomplete
              disablePortal
              options={resultOptions}
              clearOnBlur
              size="small"
              value={resultSelection}
              onInputChange={(event, value) => setResultSelection(value)}
              sx={{ marginRight: 2, my: 1, width: 200, borderRadius: "30px" }}
              renderInput={(params) => (
                <TextField size="small" {...params} label="Select Result" />
              )}
            />
          </Grid>
          {resultSelection === "General" && (
            <Grid item xs={12} md="auto">
              <Autocomplete
                disablePortal
                options={moodOptions}
                clearOnBlur
                size="small"
                value={moodSelection}
                defaultValue={moodOptions[0]}
                onInputChange={(event, value) => setMoodSelection(value)}
                sx={{ marginRight: 2, my: 1, width: 200, borderRadius: "30px" }}
                renderInput={(params) => (
                  <TextField size="small" {...params} label="Select Mood" />
                )}
                disableClearable={true}
              />
            </Grid>
          )}
          <Grid item xs={12} md="auto">
            <Autocomplete
              disablePortal
              options={typeOptions}
              clearOnBlur
              size="small"
              value={typeSelection}
              onInputChange={(event, value) => setTypeSelection(value)}
              sx={{ marginRight: 2, my: 1, width: 200 }}
              renderInput={(params) => (
                <TextField size="small" {...params} label="Select Type" />
              )}
            />
          </Grid>
          <Grid item xs={12} md="auto">
            <Button
              onClick={() => {
                setTypeSelection("Video");
                setResultSelection("");
                setMoodSelection("");
              }}
              variant="outlined"
              color="error"
              sx={{ mt: 1 }}
            >
              Reset Filter
            </Button>
          </Grid>
          <Grid item sx={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              disableElevation
              LinkComponent={NavLink}
              to="/resource/add"
              state={{
                title: "Manage Resource",
                result: resultSelection,
                type: typeSelection,
              }}
              sx={{ mt: 1 }}
            >
              + Add Resources
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <CustomizedTables columns={columnHeading} data={resourceData} />
    </>
  );
}

export default ManageResourcePage;
