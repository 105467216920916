import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { snankBarContext } from "../context/snackBarContext";
import axios from "axios";
import {
  Button,
  Grid,
  CssBaseline,
  Box,
  Typography,
  Paper,
} from "@mui/material";

import EmailField from "../components/form/EmailField";
import PasswordField from "../components/form/PasswordField";

// static files
import JoyySVG from "../static/image/joyygreenlogo.svg";

import { isEmail } from "validator";
import { login } from "../services/authApi";

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{8,20}$/;

export default function LoginPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("access_token");
    if (isLoggedIn) {
      navigate("/resource");
    }
  }, [navigate]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);

  const { displaySnackBar } = useContext(snankBarContext);

  console.log("error", error);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isEmail(email)) {
      setIsValidEmail(false);
      return;
    }

    if (!passwordRegex.test(password)) {
      setIsValidPassword(false);
      return;
    }

    setIsValidEmail(true);
    setIsValidPassword(true);

    const body = {
      email: email,
      password: password,
    };
    login(body)
      .then((data) => {
        localStorage.setItem("access_token", JSON.stringify(data.access_token));
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.access_token}`;
        setError(null);
        navigate("/resource");
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          displaySnackBar(true, "Internal Server Error", {
            backgroundColor: "red",
            color: "white",
          });
        } else {
          displaySnackBar(true, "Invalid username or password", {
            backgroundColor: "red",
            color: "white",
          });
          setError(error);
        }
      });
  };

  const handleTabKeyOnEmailField = (event) => {
    if (event.key === "Tab") {
      if (!isEmail(email)) {
        setIsValidEmail(false);
      } else {
        setIsValidEmail(true);
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Grid
          container
          component={Paper}
          sx={{
            minHeight: "58vh",
            maxHeight: "90vh",
            padding: "10px",
            margin: "20px",
            borderRadius: "30px",
          }}
          minWidth="30vh"
          maxWidth="70vh"
          elevation={15}
        >
          <CssBaseline />
          <Grid item xs={12} sm={12} md={12}>
            <Box
              sx={{
                mb: 4,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={JoyySVG}
                alt=""
                style={{
                  marginBottom: "20px",
                }}
              ></img>
              <Typography
                fontSize={{
                  md: 40,
                  xs: 30,
                }}
              >
                Sign In
              </Typography>
              <Box
                component="form"
                sx={{ mt: 2, width: "80%" }}
                onSubmit={handleSubmit}
                noValidate
              >
                <Typography fontWeight="bold">Work Email</Typography>
                <EmailField
                  placeholder="joyy@gmail.com"
                  value={email}
                  setValue={setEmail}
                  id="email"
                  name="email"
                  error={isValidEmail}
                  onKeyDown={handleTabKeyOnEmailField}
                />
                <Typography fontWeight="bold">Password</Typography>
                <PasswordField
                  placeholder="password"
                  value={password}
                  setValue={setPassword}
                  id="password"
                  name="password"
                  error={isValidPassword}
                />
                {/* {error && <RedBar>Invalid Email and Password</RedBar>} */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disableElevation
                  disabled={email && password ? false : true}
                  sx={{ mt: 3, mb: 2, fontWeight: "bold" }}
                >
                  Login
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
