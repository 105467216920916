import {
  createBrowserRouter,
  // createRoutesFromElements,
  // Route,
} from "react-router-dom";

// pages
import LoginPage from "../pages/LoginPage";
import ManageResourcePage from "../pages/ManageResourcePage";
import AddResourcePage from "../pages/AddResourcePage";

// layout
import Resource from "../layout/resource";

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        path: "/",
        element: <LoginPage />,
      },
      {
        path: "/resource",
        element: <Resource />,
        children: [
          {
            path: "/resource",
            element: <ManageResourcePage />,
          },
          {
            path: "add",
            element: <AddResourcePage />,
          },
        ],
      },
    ],
  }
])

export default router;
