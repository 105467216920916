export const resultOptions = [
  {
    label: "General",
    value: "General",
  },
  {
    label: "Low",
    value: "Low",
  },
  {
    label: "Moderate",
    value: "Moderate",
  },
  {
    label: "High",
    value: "High",
  },
];

export const moodOptions = [
  {
    label: "Peace",
    value: "Peace",
    // value: "PEACE",
  },
  {
    label: "Happiness",
    value: "Happiness",
    // value: "HAPPINESS",
  },
  {
    label: "Joyy",
    value: "Joyy",
    // value: "JOYY",
  },
  {
    label: "Bliss",
    value: "Bliss",
    // value: "BLISS",
  },
];

export const typeOptions = [
  {
    label: "Video",
    value: "Video",
  },
  {
    label: "Audio",
    value: "Audio",
  },
  {
    label: "Document",
    value: "Document",
  },
];

export const columnHeading = [
  "Sr No",
  "Result",
  "Mood",
  "Type",
  "# of Resources",
];
