import React from 'react';

const AudioPlayer = ({ audioSource }) => {
    return (
        <div style={{ height: "100%", width: "100", margin: "auto" }}>
            <audio controls src={audioSource} id="audioPlayer">
                Your browser does not support the audio tag.
            </audio>
        </div>
    );
};

export default AudioPlayer;