import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables({ columns, data }) {
  const navigate = useNavigate();
  return (
    <TableContainer
      component={Paper}
      sx={{
        maxWidth: {
          md: "100%",
          sm: "100%",
          xs: "350px",
        },
      }}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((item) => {
              return <StyledTableCell key={item}>{item}&nbsp;</StyledTableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            // <Link to={`/edit/${row.id}`} key={row.id}>
            <StyledTableRow key={row.id}
              onClick={() => {
                navigate(`/resource/add?result=${row.result}&mood=${row.mood}&type=${row.type}`);
              }}
              style={{
                cursor: "pointer",
              }}
            >
              <StyledTableCell>{index + 1}</StyledTableCell>
              <StyledTableCell>{row.result}</StyledTableCell>
              <StyledTableCell>{row.mood ? row.mood : "-"}</StyledTableCell>
              <StyledTableCell>{row.type}</StyledTableCell>
              <StyledTableCell>{row.total_resource}</StyledTableCell>
            </StyledTableRow>
          ))}
          {data.length === 0 && (
            <StyledTableRow>
              <StyledTableCell colSpan={columns.length} align="center">
                No Data Available
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
