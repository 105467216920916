import { useState, useEffect, useContext, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import { IconButton, Box, Typography, Grid, Link } from "@mui/material";
import { Stack } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from "@mui/material/CircularProgress";

// icons
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import EditIcon from "@mui/icons-material/Edit";

// custom components
import AddLinkModal from "../components/Modal";
import Dropdown from "../components/Dropdown";

import {
    addResource,
    addDocumentResourceApi,
    deleteResource,
    getResourceDetail,
} from "../services/resourceApi";
import getYoutubeVideoInfo from "../services/youtubeApi";
import { moodOptions, resultOptions, typeOptions } from "../constants";
import { snankBarContext } from "../context/snackBarContext";
import AudioPlayer from "../components/AudioPlayer";
import AudioPng from "../static/image/audio_thumbnails.png";
import DocPng from "../static/image/document_thumbnail.png";

function AddResourcePage() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalDefaultValue, setModalDefaultValue] = useState("");
    // eslint-disable-next-line
    const [error, setError] = useState(null);
    const [isFirstTime, setIsFirstTime] = useState(true)

    const [renderVideoInfoList, setRenderVideoInfoList] = useState([]);
    const [highlightedIndex, setHighightedIndex] = useState(-1);
    const [editLinkIndex, setEditLinkIndex] = useState(-1);

    const [showIframe, setShowIframe] = useState(false);
    const [iframeYoutubeVideo, setIframeYoutubeVideo] = useState(null);

    const [audioSource, setAudioSource] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();
    const queryParams = useMemo(() => {
        return new URLSearchParams(location.search);
    }, [location.search]);
    const { id } = useParams();
    const [resultSelection, setResultSelection] = useState("");
    const [moodSelection, setMoodSelection] = useState("");
    const [typeSelection, setTypeSelection] = useState("");
    const { displaySnackBar } = useContext(snankBarContext);
    const matches = useMediaQuery("(min-width:600px)");

    useEffect(() => {
        const result = queryParams.get("result")
        const type = queryParams.get("type")
        if (result || type) {
            if (isFirstTime) {
                const mood = queryParams.get("mood")
                if (mood) {
                    setMoodSelection(mood)
                    queryParams.delete('mood')
                }
                setResultSelection(result)
                setTypeSelection(type)
                queryParams.delete('result')
                queryParams.delete('type')
                setIsFirstTime(false)
            } else {
                queryParams.delete('result')
                queryParams.delete('type')
                queryParams.delete('mood')
            }
        }
        if (resultSelection && typeSelection) {
            getResourceDetail({
                result: resultSelection,
                type: typeSelection,
                mood: resultSelection === "General"
                    ? moodSelection
                    : null,
            })
                .then((response) => {
                    // setFilteredData(response.data);
                    if (typeSelection === "Video") {
                        const data = response.data.map((item) => {
                            return getYoutubeVideoInfo(item.link).then((response) => {
                                const data = response.data.items.map((ytitem) => {
                                    return {
                                        videoId: ytitem.id,
                                        title: ytitem.snippet.title,
                                        thumbnail: ytitem.snippet.thumbnails.default.url,
                                        link: "https://www.youtube.com/watch?v=" + ytitem.id,
                                        id: item.id,
                                        type: item.type
                                    };
                                });
                                return data;
                            });
                        });
                        Promise.all(data).then((data) => {
                            data = data.flat();
                            setRenderVideoInfoList(data);
                        });
                    } else {
                        setRenderVideoInfoList(response.data);
                    }
                })
                .catch((error) => setError(error.data));
            return () => {
                // user leaves the page
                setRenderVideoInfoList([]);
            };
        }
    }, [typeSelection, resultSelection, moodSelection, queryParams, isFirstTime]);

    const addAndUpdateRenderVideoInfoList = (obj, editObjectIndex = -1) => {
        setIsLoading(true);
        const tempArr = [...renderVideoInfoList];
        const res = tempArr.findIndex((item) => item.link === obj.link);

        // check if the link is already exists or not
        if (~res) {
            setHighightedIndex(res);
            displaySnackBar(true, "Link Already exists!");
            setIsLoading(false);
            setTimeout(() => setHighightedIndex(-1), 2000);
            return;
        }

        if (obj.type === 'Video') {
            getYoutubeVideoInfo(obj.link)
                .then((response) => {
                    const videoMetaData = {
                        videoId: response.data.items[0].id,
                        title: response.data.items[0].snippet.title,
                        thumbnail: response.data.items[0].snippet.thumbnails.default.url,
                        link: obj.link,
                    };

                    let links = renderVideoInfoList.map((item) => ({
                        link: item.link,
                        thumbnail: item.thumbnail,
                        title: item.title,
                        videoId: item.videoId,
                    }));
                    // editing the existing link
                    if (~editObjectIndex) {
                        links[editObjectIndex] = {
                            link: obj.link,
                            thumbnail: videoMetaData.thumbnail,
                            title: videoMetaData.title,
                            videoId: videoMetaData.videoId,
                        };
                    }

                    const body = {
                        result: resultSelection,
                        type: obj.type,
                        mood: moodSelection,
                        link: obj.link,
                        thumbnail: videoMetaData.thumbnail,
                        title: videoMetaData.title,
                        videoId: videoMetaData.videoId,
                    };
                    addResource(body)
                        .then(({ data }) => {
                            if (typeSelection === 'Video') {
                                setRenderVideoInfoList([data, ...renderVideoInfoList]);
                            }
                            displaySnackBar(true, "Resource added successfully");
                        })
                        .catch((error) => {
                            displaySnackBar(true, error.response.data.message);
                        });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            const body = {
                result: resultSelection,
                mood: moodSelection,
                type: obj.type,
                link: obj.link,
                title: obj.title,
            };
            addResource(body)
                .then(({ data }) => {
                    if (typeSelection === 'Audio') {
                        setRenderVideoInfoList([data, ...renderVideoInfoList]);
                    }
                    displaySnackBar(true, "Resource added successfully");
                    setIsLoading(false);
                })
                .catch((error) => {
                    displaySnackBar(true, error.response.data.message);
                    setIsLoading(false);
                });
        }
    };

    const addDocumentResource = (obj) => {
        setIsLoading(true);
        const file = obj.file
        let formData = new FormData();
        formData.append("result", resultSelection);
        formData.append("type", obj.type);
        formData.append("mood", moodSelection);
        formData.append("file", file);
        formData.append("title", file.name);
        addDocumentResourceApi(formData)
            .then(({ data }) => {
                if (typeSelection === "Document") {
                    setRenderVideoInfoList([data, ...renderVideoInfoList]);
                }
                displaySnackBar(true, "Resource added successfully");
                setIsLoading(false);
            })
            .catch((error) => {
                displaySnackBar(true, error.response.data.message);
                setIsLoading(false);
            });
    };

    const deleteResourceById = (id) => {
        console.log(id);
        deleteResource(id)
            .then((response) => {
                if (response.success) {
                    setRenderVideoInfoList((old) => old.filter((item) => item.id !== id));
                    displaySnackBar(true, "Resource deleted Successfully");
                }
            })
            .catch((err) => {
                displaySnackBar(true, err.response.data.message);
            });
    };

    // link actions
    const handleEditLink = (item, index) => {
        setEditLinkIndex(index);
        setModalDefaultValue(item.link);
        setIsModalOpen(true);
    };

    const handlePlayResource = (item) => {
        if (item.type === 'Video') {
            setShowIframe(true);
            setIframeYoutubeVideo(item);
        } else {
            setAudioSource(item.link)
            setTimeout(() => {
                document.getElementById('audioPlayer').play()
            }, 100)
        }
    };

    // Modal actions
    const handleModalAddBtnClick = (obj) => {
        if (obj.type === "Audio" || obj.type === "Video") {
            addAndUpdateRenderVideoInfoList(obj, editLinkIndex);
        } else {
            addDocumentResource(obj, editLinkIndex);
        }
        setIsModalOpen(false);
    };

    const handleModalAddAnotherBtnClick = (obj) => {
        if (obj.type === "Audio" || obj.type === "Video") {
            addAndUpdateRenderVideoInfoList(obj);
        } else {
            addDocumentResource(obj);
        }
        setIsModalOpen(true);
    };

    return (
        <>
            <Grid container>
                <Grid item md={typeSelection === "Video" || typeSelection === "Audio" ? 6 : 12}>
                    <Grid item md={12} xs={12} lg={12}>
                        <Typography
                            sx={{
                                ml: "15px",
                                fontWeight: "bold",
                                fontSize: {
                                    md: "22px",
                                },
                            }}
                            gutterBottom
                        >
                            Add Resources by Result and Type
                        </Typography>
                    </Grid>
                    <Grid
                        item={true}
                        md={8}
                        sx={{
                            mt: {
                                md: "5px",
                            },
                            ml: "10px",
                        }}
                    >
                        <Stack direction={{ xs: "column", sm: "row" }}>
                            <Dropdown
                                options={resultOptions}
                                value={resultSelection}
                                onChange={(event) => {
                                    setResultSelection(event.target.value);
                                }}
                                title={id ? "" : "Select Result"}
                                disable={id ? true : false}
                                width={200}
                            />
                            {resultSelection === "General" && (
                                <Dropdown
                                    options={moodOptions}
                                    value={moodSelection}
                                    onChange={(event) => {
                                        setMoodSelection(event.target.value);
                                    }}
                                    title={id ? "" : "Select Mood"}
                                    disable={id ? true : false}
                                    width={200}
                                />
                            )}
                            <Dropdown
                                options={typeOptions}
                                value={typeSelection}
                                onChange={(event) => {
                                    setTypeSelection(event.target.value);
                                }}
                                title={id ? "" : "Select Type"}
                                disable={id ? true : false}
                                width={200}
                            />
                        </Stack>
                    </Grid>
                    <Grid item md={12}>
                        <Box
                            sx={{
                                m: "10px",
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "white",
                                borderRadius: "20px",
                                padding: "20px",
                                minWidth: "300px",
                                maxWidth: "650px",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontWeight: "bold",
                                        fontSize: {
                                            md: "22px",
                                        },
                                    }}
                                >
                                    Add {typeSelection === "Video" ? "Video Links" : "Documents"}
                                </Typography>

                                <Stack direction={{ xs: "column", sm: "row" }} spacing={-2}>
                                    {!isLoading ? (
                                        <IconButton
                                            color="primary"
                                            onClick={() => {
                                                setModalDefaultValue("");
                                                setIsModalOpen(true);
                                                setEditLinkIndex(-1);
                                            }}
                                            edge="end"
                                            sx={{ mr: 2, ml: 2 }}
                                            title="Add video Links"
                                        >
                                            <AddCircleOutlineOutlinedIcon
                                                sx={{
                                                    fontSize: {
                                                        md: "30px",
                                                    },
                                                }}
                                            />
                                        </IconButton>
                                    ) : (
                                        <CircularProgress size={30} />
                                    )}
                                </Stack>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingRight: "20px",
                                    maxHeight: "calc(80vh - 200px)",
                                    overflowY: "auto",
                                }}
                            >
                                {renderVideoInfoList.map((item, index) => {
                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: "flex",
                                                minWidth: "300px",
                                                maxWidth: "700px",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Link
                                                href={item.link}
                                                target="_blank"
                                                sx={{
                                                    maxWidth: "60vw",
                                                    border: "2px solid",
                                                    padding: "10px",
                                                    borderRadius: "15px",
                                                    display: "flex",
                                                    margin: "10px",
                                                    textDecoration: "None",
                                                    fontSize: "15px",
                                                    ...(highlightedIndex === index && {
                                                        borderColor: "red",
                                                    }),
                                                }}
                                                className={
                                                    highlightedIndex === index ? "shakeeffect" : ""
                                                }
                                                noWrap
                                            >
                                                <img
                                                    src={typeSelection === "Video" ? item.thumbnail : typeSelection === "Audio" ? AudioPng : DocPng}
                                                    alt="cat"
                                                    width="100px"
                                                    height="100px"
                                                    style={{ borderRadius: "10px" }}
                                                />
                                                <Box sx={{ margin: "8px" }}>
                                                    <Typography
                                                        component="h6"
                                                        sx={{ fontWeight: "bold" }}
                                                    >
                                                        {item.title}
                                                    </Typography>
                                                    {item.link}
                                                </Box>
                                            </Link>
                                            {typeSelection === "Video" && (
                                                <IconButton
                                                    color="primary"
                                                    edge="end"
                                                    onClick={() => handleEditLink(item, index)}
                                                    style={{ backgroundColor: "transparent" }}
                                                    title="Edit link"
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            )}

                                            <IconButton
                                                color="error"
                                                onClick={() => deleteResourceById(item.id)}
                                                edge="end"
                                                disabled={
                                                    renderVideoInfoList.length === 1 ? true : false
                                                }
                                                style={{ backgroundColor: "transparent" }}
                                                title="Remove link"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                            {typeSelection === "Video" ? matches && (
                                                <IconButton
                                                    color="primary"
                                                    edge="end"
                                                    onClick={() => handlePlayResource(item)}
                                                    style={{ backgroundColor: "transparent" }}
                                                    title="Play Video"
                                                >
                                                    <PlayArrowIcon />
                                                </IconButton>
                                            ) : typeSelection === "Audio" && (
                                                <IconButton
                                                    color="primary"
                                                    edge="end"
                                                    onClick={() => handlePlayResource(item)}
                                                    style={{ backgroundColor: "transparent" }}
                                                    title="Play Audio"
                                                >
                                                    <PlayArrowIcon />
                                                </IconButton>
                                            )
                                            }
                                        </Box>
                                    );
                                })}
                                {renderVideoInfoList.length === 0 && (
                                    <Typography
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100px",
                                        }}
                                    >
                                        No {typeSelection === "Video" ? "Links" : "Documents"} added
                                        yet
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                {matches && typeSelection === "Video" && (
                    <Grid item md={6}>
                        {showIframe ? (
                            <Box>
                                <Typography variant="h1" sx={{ p: "10px" }} align="center">
                                    {iframeYoutubeVideo.title}
                                </Typography>
                                <iframe
                                    width="100%"
                                    style={{
                                        minHeight: "80vh",
                                        maxHeight: "90vh",
                                        borderRadius: "15x",
                                    }}
                                    allowFullScreen
                                    src={`https://www.youtube.com/embed/${iframeYoutubeVideo.videoId}?autoplay=1&mute=1`}
                                    title={`${iframeYoutubeVideo.title}`}
                                ></iframe>
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    width: "100%",
                                    minHeight: "80vh",
                                    maxHeight: "90vh",
                                    border: "3px solid white",
                                    borderRadius: "15px",
                                }}
                            >
                                <Typography align="center">
                                    Click on the Play icon to play video
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                )}
                {typeSelection === 'Audio' && <AudioPlayer audioSource={audioSource} />}
                <AddLinkModal
                    isOpen={isModalOpen}
                    value={modalDefaultValue}
                    type={typeSelection}
                    handleClose={() => setIsModalOpen(false)}
                    onAdd={handleModalAddBtnClick}
                    onAddAnother={handleModalAddAnotherBtnClick}
                />
            </Grid>
        </>
    );
}

export default AddResourcePage;
