import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppBar from "./layout/AppBar";
import Main from "./layout/Main";
import DrawerHeader from "./layout/DrawerHeader";

import JoyySVG from "../static/image/joyylogo.svg";

const drawerWidth = 240;

export default function CustomDrawer({ children }) {
  const [open, setOpen] = useState(true);
  const [appBarLabel, setAppBarLabel] = useState("Manage Resources");

  const matches = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const handleDrawer = () => {
    setOpen(!open);
  };

  const drawerItemList = [
    {
      title: "Manage Resources",
      icon: <ManageHistoryIcon />,
      onClick: () => {
        setAppBarLabel("Manage Resources");
        if (matches) {
          setOpen(false);
        }
        navigate("/resource");
      },
    },
    {
      title: "Add Resource",
      icon: <AddIcon />,
      onClick: () => {
        setAppBarLabel("Add Resource");
        if (matches) {
          setOpen(false);
        }
        navigate("/resource/add");
      },
    },
    {
      title: "Logout",
      icon: <LogoutOutlinedIcon />,
      onClick: () => {
        localStorage.clear();
        navigate("/");
      },
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ color: "black", backgroundColor: "white" }}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {appBarLabel}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: "primary.main",
          },
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: {
              xs: "100vw",
              md: drawerWidth,
            },
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Box
          sx={{
            m: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={JoyySVG} alt="joyo logo" width="100px"></img>
          <Typography
            variant="h1"
            sx={{
              mt: 2,
              fontWeight: "700",
              fontSize: "12px",
              letterSpacing: "0.12em",
            }}
            color="white"
            align="center"
          >
            Admin Portal
          </Typography>
        </Box>

        <Divider />
        <List sx={{ color: "white" }}>
          {drawerItemList.map((item) => (
            <ListItem
              key={item.title}
              disablePadding
              component={Button}
              onClick={item.onClick}
            >
              <ListItemButton>
                <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={item.title}
                  sx={{
                    color: "white",
                    fontWeight: "700",
                    fontSize: "12px",
                    letterSpacing: "0.12em",
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Main
        open={open}
        sx={{
          backgroundColor: "#F4FAF5",
          height: "100vh",
          ...(open && matches && { display: "none" }),
        }}
      >
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
}
