import { Outlet, useNavigate } from "react-router-dom";
import CustomDrawer from "../components/Drawer";
import { useEffect } from "react";

export default function Resource() {
  const navigate = useNavigate();
  const auth = localStorage.getItem("access_token");

  useEffect(() => {
    if (!auth) {
      navigate("/", { replace: true });
      return;
    }
  }, [auth, navigate]);

  if (!auth) return null;

  return (
    <CustomDrawer>
      <Outlet />
    </CustomDrawer>
  );
}
